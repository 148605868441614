export default function IcelandicDate({ dags, type }) {
  const month = ["Janúar","Febrúar","Mars","Apríl","Maí","Júní","Júlý","Ágúst","September","Október","Nóvember","Desember"];
  const shortMonth = ["Jan","Feb","Mar","Apr","Maí","Jún","Júl","Ág","Sept","Okt","Nóv","Des"];

  const date=new Date(dags);
  console.log(date.getFullYear())

  let rtn="";
  switch(type) {
    case 'full':
      return  rtn= date.getDate()+". "+month[date.getUTCMonth()]+" "+date.getFullYear();
    case 'short':
      return  rtn= date.getDate()+". "+ parseInt(date.getMonth()+1)+"."+date.getFullYear();
    case 'Y':
        return  rtn= date.getFullYear();
    case 'semi':
        return  rtn=date.getDate()+". "+shortMonth[date.getUTCMonth()]+" "+date.getFullYear();
    default:
      return 'foo';
  }
 
  return (  <>{rtn}</>);
}