import React, { useEffect, useState } from 'react'
import  { Link } from 'react-router-dom'
import './styles.css'
import { STRAPIURL } from '../../Constant';

export default function Employee({attributes}) {
    const [uri, seturi] = useState();
    console.log(attributes);
    useEffect
    (()=>{  
      if(attributes.Image.data) seturi(STRAPIURL+attributes.Image.data.attributes.url)
    },[attributes.Image]) 
  
    
  return (
  
      <div className='group w-64 h-80 border border-[#ECE9EE] rounded-3xl flex-row justify-center align-middle hover:scale-110 duration-300 '>
        <div  className="relative top-2 left-2 w-60 h-64">{uri?<img className="object-top rounded-2xl object-cover w-60 h-60"  src={uri+"?w=200"}/>:<></> }</div>
        <div className='justify-center align-middle '>
          <div className='no-underline w-64 text-slate-800 text-center font-bold text-lg group-hover:text-slate-800'>{attributes.Name}</div>
          <div  className='w-64 text-center  text-slate-500 text-m group-hover:text-slate-500 '>{attributes.Title}</div>   
        </div>      
      </div>
  
  )
  //<Link to={"/employee/"+name.replaceAll(' ', '-')}>more... </Link>
}

/*

  
.empDetail{
    padding-top: 25px;
    align-items: center;
    text-align: center ;
  
  }
  .empName{
    font-size: 25px;
    font-weight: 700; 
    font-family: "Montserrat", sans-serif;
    color: #1E1B4A;
  }
  .emptitle{
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.92px;
    text-align: center;
    color: #707070;


  }

*/