import React, { useContext, useState, useEffect } from 'react'
import { Routes, Route, Link, useParams, useLocation } from "react-router-dom"

import './styles.css'
import GlobalContext from '../../state/GlobalContext';

export default function Banner() {
  const {state} = useContext(GlobalContext);
  const location = useLocation()
  const [Scrolled, SetScrolled]=useState(false);
  const [height, SetHeight]=useState( window.innerHeight);
  useEffect(() => {

    console.log(document.getElementById('poster').clientHeight)
    const onscroll = () => {
 
      if(window.scrollY >0)
        SetScrolled(true);
      else 
      {
        if(document.getElementById('poster').clientHeight===200)
        SetScrolled(false);

      }
      
    };
    window.addEventListener("scroll", onscroll);
    return () => {
      window.removeEventListener("scroll", onscroll);
    };
  }, [window.innerHeight, window.scrollY]);


  return (
  
    <div id="poster" className='hidden md:block banner shadow' style={(location.pathname!=="/" || Scrolled)? { height:200, position: 'sticky'} : {height:800, position: 'sticky'}}>
      <div class="layer">
      {(location.pathname==="/" && !Scrolled) ?<div class="textbox">
        <div className='bannerTitle'>{state.pageTitle.title}</div>
        <div className='seperator'></div>
        <div className='bannerSubTitle'>{state.pageTitle.subtitle}</div>
        </div> : <></>}        
      </div></div>   
  )
}
