export default function Interest({ attributes, title }) {

  return (  <>
          {attributes && (
            <div className="border rounded-xl w-full md:w-96 p-4">
              <div className="flex items-center">
              <div className="w-5 h-0.5 bg-ef-yellow "></div>
              <span className="text-xl ml-2 font-semibold">{title}</span>
              </div>
              <ul class=" text-surface list-outside">
                {" "}
                {attributes.split("\n").map((itm) => {
                  return <li className="w-full text-sm">{itm}</li>;
                })}
              </ul>
            </div>
          )}
          </> 
  );
}