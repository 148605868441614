import React from 'react';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: 'http://185.112.144.96:1337/graphql',
  });
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = "0e023a90e2a3fee7d81ce16aabe7c6c1efa58b9cbea49d3aa628f13d25356dfd13d4825bda8c2f79fa469c19bf3b56c4f4ff91edfa78640d6e4e3f6b582d9c336448518d576577ad77938d7ccd4a6379f70b69f9050a741d420c5a38b608cf40d3feee552236f05d59e3a31a0dbc7ba7c9fbf8e85ebc2ab9fb27a2b9311c3d8f";
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

const client = new ApolloClient({
    link: authLink.concat(httpLink),
     cache: new InMemoryCache(),
});
const ApolloAppProvider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default ApolloAppProvider;