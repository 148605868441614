import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook ,
  FaYoutube ,
  FaRegCopyright ,
  FaRegClock ,
  
} from "react-icons/fa";

import {
  MdAlternateEmail   
} from "react-icons/md";
import {
  BsFillTelephoneFill ,
  BsGeoAlt ,
} from "react-icons/bs";

import foot from "../../images/eflingLogo.png";
import trans from "../../images/bluetrans.png";

export default function Footer({ attributes }) {
  return (
    <div className="w-full bg-ef-blue text text-white bg-ef-pattern bg-no-repeat pt-1 px-10">
      <div className="p-2 w-full grid-cols-1 md:flex md:justify-between gap-2">
        <div className="max-w-sm">
          <img className="w-40" src={foot} />
         <div className="pt-2 text-sm">
          Efling Sjúkraþjálfun ehf. var stofnuð árið 1995 af Einari Einarssyni,
          Sigrúnu Jónsdóttur og Stefáni Ólafssyni.
          <p className="pt-2">Framkvæmdastjóri er Ásta Þorsteinsdóttir.</p>
          </div>
          <div className="flex">
            <a href="https://www.facebook.com/efling.sjukrathjalfun">
              <FaFacebook  className="w-12 h-12 m-2 hover:no-underline hover:opacity-70 hover:scale-125 hover:fill-ef-yellow transition-all duration-100" />
            </a>
            <a href="https://www.youtube.com/channel/UCRnTCQjxuWTK9_-bJZ8v0qw">
              <FaYoutube  className="w-12 h-12 m-2 hover:no-underline hover:opacity-70 hover:scale-125 hover:fill-ef-yellow transition-all duration-100" />
            </a>
          </div>
        </div>
        <div className="flex flex-col text-m pt-8 md:pt-1">


          <div className="font-bold pt-1">Hraðtenglar</div>          
          Starfsfólk
          <br />
          Um Eflingu
          <br />
          Þjónusta
          <br />
          Vörur
        </div>
        <div className="flex flex-col text-m pt-8 md:pt-1 ">
          <div className="font-bold pt-1">þjónustan okkar</div>
         

        </div>
        <div className="flex flex-col text-m pt-8 md:pt-1">
            <div className="font-bold pt-1">Hafa samband</div>
            <div className="flex align-middle pt-1"><BsFillTelephoneFill /><div className="px-2">461 2223</div></div>
            <div className="flex align-middle pt-1"><MdAlternateEmail  /><div className="px-2">Efling@eflingehf.is</div></div>
            <div className="flex align-middle pt-1"><FaRegClock  /><div className="px-2">mán-föstudaga frá 08:00- 17:00</div></div>
            <div className="flex align-middle pt-1"><BsGeoAlt  /><div className="px-2">Krónan 3 hæð Hafnastræti 97 600 Akureyri</div></div>
            <div className="flex align-middle pt-1"><BsGeoAlt  /><div className="px-2">Kaupangur</div></div>

        </div>
      </div>
      <div className="pt-1 border-t border-opacity-20 ">
        
        <div className="flex w-full pt-1 justify-end"><FaRegCopyright className=" fill-gray-400" /><div className="px-2 text-gray-400">{new Date().getFullYear()} Efling. Allur réttur áskilinn.</div></div>
      </div>
    </div>
  );
  //<Link to={"/employee/"+name.replaceAll(' ', '-')}>more... </Link>
}
