import { useEffect, useState } from "react";

export default function ProductsDetail({ title, data }) {
  const [header, setHeader] = useState("");
  const [runonce, setrunonce] = useState();
  useEffect(() => {  
  switch( title ) {
    case "Boltar_og_rullur":
      setHeader("Boltar og rúllur");
      break;
    case "Hlifar_og_spelkur":
      setHeader("Hlífar og spelkur");
        break;
    case "kaling":
    setHeader("Kæling");
      break;
    case "Studningur":
      setHeader("Stuðningur");
      break;
    case "Ymislegt":
      setHeader("Ýmislegt");
      break;
    default:
      setHeader(title);
  }

  
}, [runonce]);
  return (
    <>
      {data && (
        <div className="relative mt-24  md:mt-10">
          <div className="flex items-center">
                  <div className="w-10 h-1 bg-ef-yellow "></div>
                  <span className="text-3xl ml-2 font-semibold uppercase">
                    {header}
                  </span><div className="ml-2 w-10 h-1 bg-ef-yellow "></div>
                </div>
          
          <div className="ml-2 pt-4 flex flex-wrap justify-around gap-5 ">
          {data.map((itm) => {
            return (
              <div className="border rounded-xl w-full md:w-72 p-4">
                <div className="flex items-center">
                  <div className="w-5 h-0.5 bg-ef-yellow "></div>
                  <span className="text-xl ml-2 font-semibold">
                    {itm.attributes.Name}
                  </span>
                </div>
                <div className="flex justify-center">
                {itm.attributes.Photo &&(
                  itm.attributes.Photo.data.map((itm2) => {
                    return (
                      <img
                        className=" w-60 p-4 "
                        src={
                          "http://185.112.144.96:1337" +
                          itm2.attributes.url +
                          "?w=200"
                        }
                      />
                    )
                    }
                    )
                  )
                }
                  </div>
              </div>
            )
          })}
          </div>
          </div>
        
      )}
    </>
  );
}
