import React, { useContext, useEffect } from "react";
import { GET_HOME } from "../GraphQL/Queries";
import News from "../Components/News";
import { GET_FEW_NEWS } from "../GraphQL/Queries";
import { normalize } from "../GraphQL/Normalize";
import { STRAPIURL } from '../Constant';
import GlobalContext from "../state/GlobalContext";

import { useQuery } from "@apollo/client";

export const getDates=(date)=>{

  var date1 = new Date(date);  
           var month = date1.toLocaleString('default', { month: 'long' });
           let dateMDY = `${date1.getDate()}. ${month} ${date1.getFullYear()}`;
           return dateMDY;
}
export default function Home() {
  const { state, dispatch } = useContext(GlobalContext);
  const { loading, error, data } = useQuery(GET_HOME);
  const NewsData = useQuery(GET_FEW_NEWS);

  useEffect(() => {
    if (data)
      dispatch({
        type: "ADD_PAGE_TITLE",
        payload: {
          title: data.home.data.attributes.title,
          subtitle: data.home.data.attributes.subtext,
        },
      });
  }, [data]);

  console.log(News);

  if (!data) return <div>loading</div>;

  if (NewsData.data) {
    console.log(NewsData.data.stories.data);
    const datanorm = normalize(NewsData.data.stories);
    console.log(datanorm);
    return (
      <><div>
         {datanorm ? 
           
                  <div className='pt-24 md:px-2 md:pt-4 md:grid md:grid-cols-2'>
                  {
                  datanorm[0].img &&  <div className="" ><img className="rounded-2xl" src={STRAPIURL+datanorm[0].img.url}/></div>
                  }
                  <div className="pt-2 md:ml-20">
                <div className='md:text-3xl uppercase font-bold text-yellow-400'>{getDates(datanorm[0].date)}</div>
                 <div className="h-0.5 w-48 bg-yellow-400 mt-2 mb-2"> </div>
                 <div className="md:text-4xl font-bold text-gray-900">{datanorm[0].title}</div>
                 <div className="text-gl font-normal text-neutral-500">{datanorm[0].summary}</div>
                 </div>
                 </div>
               
               : (
            <></>
          )}

      </div>
      <div className="hidden  md:block h-0.5 w-full bg-yellow-400 mt-16 mb-16"> </div>
        <div className="relative border mt-10 md:mt-0 md:grid md:grid-cols-2 md:gap-8">
          {datanorm ? (
            datanorm.slice(1).map((val) => {
              return (
                  <News {...val}></News>               
              );
            })
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}
