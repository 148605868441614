import  {createContext, useReducer} from 'react';
let initialState = {
    employees: [],
    filteredEmployees: [],
    filters:[],
    filter:"... Allt starfsfólk",
    pageTitle:""
}

const GlobalContext = createContext();

const GlobalContextProvider = (props) => {
const reducer = (state, action)=>{
    switch(action.type){

        case 'ADD_EMPLOYEES':
            return{...state, employees : action.payload}
            case 'FILTERED_EMPLOYEES':
                return{...state, filteredEmployees : action.payload}    
            case 'ADD_FILTERS':
                return{...state, filters : action.payload} 
            case 'CHANGE_FILTER':
                return{...state, filter : action.payload}
            case 'ADD_PAGE_TITLE':
                return{...state, pageTitle : action.payload}                  
            default:
                return state;

        }  
    } 
    
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <GlobalContext.Provider value={{state, dispatch}}>
            {props.children}
        </GlobalContext.Provider>
    )
}
export {GlobalContextProvider}
export default GlobalContext
