import { useQuery } from '@apollo/client';
import React from 'react'
import { GET_SERV } from '../GraphQL/Queries';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';


export default function Service() {
    
    const { loading, error, data } = useQuery(GET_SERV);
  
    if(loading) return <div>loaddding.....</div>

    console.log(data);
    
    if(data)        
    return (

        <div className="relative mt-32  md:mt-10">
          <div className="flex items-center">
                  <div className="w-10 h-1 bg-ef-yellow "></div>
                  <span className="text-3xl ml-2 font-semibold uppercase">
                    {data.service.data.attributes.Title}
                  </span><div className="ml-2 w-10 h-1 bg-ef-yellow "></div>
                </div><div className="mt-10 mb-10 font-medium" >                
                <BlocksRenderer content={data.service.data.attributes.Text} />            
                </div>
            </div>
    )
}
