import React, { useState, useEffect, useContext} from 'react'

import  { Link } from 'react-router-dom'
import Employee from "../Components/Employee"
import GlobalContext from '../state/GlobalContext';
import { motion } from "framer-motion"
import {  useQuery } from '@apollo/client';
import {GET_EMPLOYEES } from "../GraphQL/Queries";


export default function Employees() {
    const {state, dispatch} = useContext(GlobalContext);
    const { loading, error, data } = useQuery(GET_EMPLOYEES);
  
    useEffect(() => {
        if(loading) return;     

      var tags =[];
      if(data.employees.data){
        console.log(data.employees.data);
      data.employees.data.map(filteredName => {   
        if(filteredName.attributes.Tags){
          filteredName.attributes.Tags.forEach(element => {
            console.log(element.name)     ;
              tags.push(element.name)
          })
          
      }});
        tags.push("... Allt starfsfólk")
        var list = tags.filter((x, i, a) => a.indexOf(x) == i).sort()
      }   
      dispatch({type: "ADD_FILTERS", payload: list});
      dispatch({type: "ADD_PAGE_TITLE", payload: "Starfsfólk"});
      dispatch({type: "ADD_EMPLOYEES", payload: data.employees.data});
      dispatch({type: "FILTERED_EMPLOYEES", payload: data.employees.data});
	}, [data]);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

      

    if(!state.filteredEmployees) return <div>loading.....</div>
    if (state.filteredEmployees) {
      
        return <div className='pt-24 md:pt-2'><div  className='flex-tags '>{state.filters?state.filters.map((val)=>
            {
               
                return <div key={val} className={`tagsBlock ${state.filter === val ? "tagsBlockselected" : ""}`} onClick={
                    ()=>{
                        dispatch({type: "CHANGE_FILTER", payload: val});
                        if (val==="... Allt starfsfólk" ){
                            dispatch({type: "FILTERED_EMPLOYEES", payload: state.employees});
                        }
                        else{
                          console.log(val)
                        var list = state.employees.filter(function (el) {
                          
                            return el.attributes.Tags && el.attributes.Tags.find( c => c.name === val );                           
                        }) 
                        dispatch({type: "FILTERED_EMPLOYEES", payload: list});
                    }
                        
                    }}
                >{val}</div>
            }):<></>}</div>
           
            <div className="pt-4 flex flex-wrap justify-around gap-5">{state.filteredEmployees?state.filteredEmployees.map((val)=>{
              console.log(val);
            return <Link className='hover:no-underline'  to={`/Employee/${val.id}/${val.attributes.Name}`}  ><Employee key={val.id} {...val}></Employee></Link>
        }):<></>}</div></div>
    }
}
