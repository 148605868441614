import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMenuSharp } from "react-icons/io5";
import logo from "../../images/eflingLogo.png";
import { menusItems } from "../../Constant";


export default function SiteHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen((open) => !open);
  };
  return (
    <header
      className=" fixed
    top-0 left-0 flex justify-end md:justify-around z-50 w-full h-24"
    >
      <div className="absolute w-full md:w-60 h-20 md:h-32 left-0 md:left-24 bg-ef-blue flex md:align-middle md:justify-center  md:rounded-b-3xl">
        <a href="#" className="absolute top-1">        
          <img src={logo} className="w-28 pt-2 md:w-40 ml-2 md:ml-0" alt="logo" />
        </a>
      </div>
      <div className="relative justify-center align-middle ml-96 mr-10 w-full max-w-7xl h-16 flex  bg-ef-header rounded-full top-7 hidden md:block">
        <ul className="absolute w-full flex top-3 justify-evenly">
        {menusItems.map(({ title, url }) => {
          return (
            <li key={title} className="group no-underline p-2 px-4 uppercase text-white hover:bg-white hover:text-green-700 rounded-full ">              
              <Link className="group-hover:text-black mb-4 hover:no-underline" to={url}>{title}</Link>             
            </li>
          )
        })}
        </ul>
      </div>
      <IoMenuSharp
        className="absolute h-16 pt-3 w-16 fill-ef-yellow md:hidden "
        onClick={toggleMenu}
      />
      <div className={`bg-white md:hidden ${!isOpen && 'hidden'} absolute top-20 h-max w-full drop-shadow-2xl ${isOpen ? 'opacity-95':'opacity-0'} transition-opacity duration-300 justify-end`} >
        <ul className="flex-row text-ef-blue m-7 font-bold h-max text-right justify-end">
        {menusItems.map(({ title, url }) => {
          return (
            <li className="relative text-right " key={title}>              
              <Link className="text-2xl " onClick={toggleMenu} to={url}>{title}</Link>             
            </li>
          )
        })}
        </ul>
      </div>
    </header>
  );
}
