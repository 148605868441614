export const API ="http://PC-FDIS-Stebbi:1337" 

export const STRAPIURL="http://185.112.144.96:1337";


export const menusItems = [
    {
        url: "/",
        title: "Home",
    },
    {
        url: "/Employees",
        title: "Starfsmenn",
    },
    {
        url: "/Service",
        title: "Þjónusta",
    },
    {
        url: "/Products",
        title: "Vörur",
    }
    ,
    {
        url: "/knowhow",
        title: "Fróðleikur",
    }  ,
    {
        url: "/About",
        title: "Um Eflingu",
    } 
  ];
