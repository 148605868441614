import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import EmployeeDetail from "../Components/EmployeeDetail";
import { GET_PRODUCTS } from "../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import GlobalContext from "../state/GlobalContext";
import ProductsDetail from "../Components/ProductsDetail";

export default function Products() {
  const [groups, setGroups] = useState([]);
  const { loading, error, data } = useQuery(GET_PRODUCTS);
  useEffect(() => {
    if (data) {
      console.log(data);
      const test = [
        ...new Set(data.products.data.map((item) => item.attributes.Type)),
      ];
      setGroups(test);
      console.log(groups);
    }
  }, [data]);

  if (!data) return <div>loading</div>;
  return (
    <>
      {groups.map((element) => {
        return <ProductsDetail key={element} title={element} data={data.products.data.filter(item => item.attributes.Type.includes(element))}/>
      })}
    </>
  );
}
