import React, {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom'
import EmployeeDetail from "../Components/EmployeeDetail"
import {GET_EMPLOYEE } from "../GraphQL/Queries";
import {  useQuery } from '@apollo/client';
import GlobalContext from '../state/GlobalContext';

export default function Employee() {
  const {state, dispatch} = useContext(GlobalContext);
    const {id}  = useParams();

const { loading, error, data } = useQuery(GET_EMPLOYEE, {
  variables:{id:id}
});

if(data) 
  console.log(data.employee.data.attributes.Name);
    if(!data) return <div>loading</div>
return <>
<EmployeeDetail key={data.employee.data.id} {...data.employee.data}> </EmployeeDetail>

</>
}