import { gql } from '@apollo/client';

export const GET_EMPLOYEE = gql`
  query GetUser($id: ID!) {
   employee  (id: $id) {data {id, attributes {Name, Tags, Bio, Email, Datestarted, DateFromGratuation, Education, WorkExperience, Interests, Abstract,  Title, Image{data{attributes{url}}}}}}
  }
`;

export const GET_EMPLOYEES = gql`
  query GetUsers {
   employees  (pagination: {limit:50}) {data {id, attributes {Name, Tags, Title, Image{data{attributes{url}}}}}}
  }
`;

export const GET_HOME = gql`
  query GetHomeCont {
   home{data{attributes{title, subtext}}}
  }
`;

export const GET_FEW_NEWS = gql`
  query GetNews {
    stories(sort: "date:desc", pagination: {limit:3}){
    data{attributes{title, summary, date, img{data{attributes{url}}}}}
    }  
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts {
   products (pagination: {limit:50}){
   data{attributes{Name, Type,  Photo{data{attributes{url,previewUrl, width, height}}}, publishedAt}}
  }
  }
`;

export const GET_KNOWHOWS = gql`
  query GetKnowhos {
   knowhows (pagination: {limit:50}){
   data{attributes{Title, Text}}
  }
  }
`;

export const GET_ABOUT = gql`
  query GetAbout {
    about{data{attributes{Title,Text}}}
  }  
`;
export const GET_SERV = gql`
  query GetService {
    service{data{attributes{Title,Text}}}
  }  
`;
