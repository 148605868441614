import React, { useEffect, useState } from "react";
import Interest from "../Interest";
import { Link } from "react-router-dom";

import {
  BlocksRenderer,
  type BlocksContent,
} from "@strapi/blocks-react-renderer";
import IcelandicDate from "../IcelandicDate";

export default function EmployeeDetail({ attributes }) {
  const [uri, seturi] = useState();
  console.log(attributes);
  useEffect(() => {
    if (attributes.Image.data)
      seturi(
        "http://185.112.144.96:1337" + attributes.Image.data.attributes.url
      );
  }, [attributes.Image]);

  return (
    <div className="relative mt-24 md:mt-8 mb-8 flex-row">
      <div className="md:flex">
        <div className="">
          <div className=" border p-8 border-gray-600 rounded-3xl w-full h-fit md:flex">
              {uri ? (
                <img className="rounded-2xl border w-60 " src={uri + "?w=200"} />
              ) : (
                <></>
              )}
            <div className="mt-10 ml-4 md:ml-10 w-full">
              <div className="text-lg md:text-5xl text-indigo-950 font-bold">
                {attributes.Name}
              </div>
              <div className="flex items-center mb-7 ">
              <div className="w-8 h-1 bg-ef-yellow "></div>
              <span className="text-neutral-500 ml-2  font-semibold md:text-2xl "> {attributes.Title}</span>
              </div>
              <div className="prose ">
                {attributes.Abstract ? (
                  <BlocksRenderer content={attributes.Abstract} />
                ) : (
                  <></>
                )}
                <div className=" flex-row md:flex justify-between">
              <div className="flex items-center">
              <div className="w-5 h-0.5 bg-ef-yellow "></div>
              <span className="text-xl ml-2 font-semibold">Hóf störf hjá Eflingu</span>
              </div>
              <IcelandicDate dags={attributes.Datestarted} type="full"></IcelandicDate>
            
              <div className="flex items-center">
              <div className="w-5 h-0.5 bg-ef-yellow "></div>
              <span className="text-xl ml-2 font-semibold">Útskrifaðist</span>
              </div>          
                <IcelandicDate dags={attributes.DateFromGratuation} type="semi"></IcelandicDate>
            </div>
            </div>
            </div>{" "}
          </div>
          <div className="pt-8">
            {attributes.Bio ? (
              <BlocksRenderer content={attributes.Bio} />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="md:ml-4 flex-row  space-y-4">




          {attributes.Interests && (<Interest title="Áhugasvið" attributes={attributes.Interests}></Interest>)}
          {attributes.Education && (<Interest title="Menntun" attributes={attributes.Education}></Interest>)}
          {attributes.WorkExperience && (<Interest title="Starfsferill" attributes={attributes.Education}></Interest> )}
        </div>
      </div>
    </div>
  );
}