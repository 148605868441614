import React, { useContext, useState } from 'react'
import { STRAPIURL } from '../../Constant';

import './styles.css'

export default function News({title, summary, date, img}) {    
  const date1 = new Date(date);  // 2009-11-10
const month = date1.toLocaleString('default', { month: 'long' });
let dateMDY = `${date1.getDate()}. ${month} ${date1.getFullYear()}`;

    return <div className='pb-5'>
       {
                img &&  <div ><img className="rounded-2xl mb-5" src={STRAPIURL+img.url}/></div>
            }
     
      <div className='md:text-xl uppercase font-bold text-yellow-400'>{dateMDY}</div>
      <div className="h-0.5 w-48 bg-yellow-400 mt-2 mb-2"> </div>
      <div className="md:text-3xl font-bold text-gray-900">{title}</div>
      <div className="text-gl font-normal text-neutral-500">{summary}</div>
    
    </div>  
}


/*
.NewsTitle{
  font-size: 30px;
  font-weight: 700;
  color: #141724;
}
.NewsAbstract{
  font-size: 20px;
  font-weight: 400;
}
.NewsImg{
   width: 100%;
  transition: 0.5s;
  border-radius: 20px; 
  margin-bottom: 20px;  
}
 */